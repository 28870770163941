<template>
    <div class="checkTask">
        <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>受检管理</el-breadcrumb-item>
          <el-breadcrumb-item>检测任务</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tableBox">
          <div class="tableTop">
            <div class="topleft">
                <div class="download" @click="createTask">创建任务</div>
            </div>
            <div style="display:flex">
                    <div class="search">
                        <el-input
                            placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            @change="seachLike"
                            class="elSerch"
                            v-model="allSearch">
                            <template slot="append">搜索</template>
                        </el-input>
                    </div>
                    <div  v-if="!isDelete" class="delete" @click="remove">删除</div>
                    <div v-if="isDelete" class="deleteNo">删除</div>
               </div>
          </div>
      </div>
      <!-- table -->
      <CheckTaskTable ref="checkResf"/>
    </div>
</template>
<script>
import CheckTaskTable from '@/components/table/checkTaskTable.vue'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
        CheckTaskTable,
    },
    data(){
        return{
            //search
            isDelete:true,
            allSearch:null,
            deleteId:[],//删除的id
        }
    },
    methods:{
        //创建检测任务  
        createTask(){
            this.$router.push({path:'/checkTask/createTask'})
        },
        //删除任务
        remove(){
            this.$message.error("暂不支持");
        },
        //模糊查询
        seachLike(){
            this.$refs.checkResf.getData(1,10,this.allSearch)
        }
    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
    border-radius: 4px 0 0 4px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
    
  }

//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color: #00CCCC !important;
    color: #fff !important
    background-color: #00CCCC !important;
}
//搜索框样式--搜索部分尾部样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }

//niActive
.deleteNo
    width: 64px
    height: 32px
    background-color: #f4f4f4
    text-align: center
    line-height:32px
    color: #aeaeae
    font-size: 14px
    margin-left: 16px
    cursor not-allowed
    border-radius: 4px

//component style
.checkTask
    overflow hidden
    .tableBox
        overflow hidden 
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            justify-content: space-between
            margin-top: 10px
            .topleft
                display: flex
                .download
                    width: 116px
                    height: 32px
                    background-color: #00CCCC
                    font-size:14px 
                    line-height: 32px 
                    text-align: center
                    color: #fff
                    border-radius: 2px 
                    cursor pointer
                
            .create 
                margin-left: 16px
            .search
                height: 32px
                margin-left: 450px !important
            .delete
                width: 64px
                height: 32px
                background-color: #FC5555
                text-align: center
                line-height:32px
                color: #fff
                font-size: 14px
                margin-left: 16px
                cursor pointer
                border-radius: 4px
            
</style>